import { reducer as reduxFormReducer } from 'redux-form';
import uiReducer from './ui/reducer';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import rootSaga from './sagas';
import createSagaMiddleware from 'redux-saga';
import errorReducer from './error/reducer';
import unitReducer from './unit/reducer';
import contractReducer from './contract/reducer';

const reducer = combineReducers({
    contract: contractReducer,
    error: errorReducer,
    form: reduxFormReducer,
    unit: unitReducer,
    ui: uiReducer,
});

const sagaMiddleWare = createSagaMiddleware();

const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(logger).concat(sagaMiddleWare),
});

sagaMiddleWare.run(rootSaga);

export default store;
