import { Unit } from '@src/interfaces/RootState';
import { RESET } from '../ui/actions';
import {
    SET_BANK_COUNTRY_LIST,
    SET_BANK_LIST,
    SET_CLAIM_TYPE_LIST,
    SET_COUNTRY_LIST,
    SET_EMPLOYEE_LIST,
    SET_LANGUAGE_LIST,
    SET_PARTNER_LIST,
    SET_PRODUCT_BRAND_LIST,
    SET_PRODUCT_GROUP_LIST,
    SET_SHOP_LIST,
    SET_WARRANTY_LIST,
} from './actions';
const initialState: Unit = {
    countryList: null,
    bankList: null,
    bankCountryList: null,
    languageList: null,
    partnerList: null,
    employeeList: null,
    shopList: null,
    productGroupList: null,
    productBrandList: null,
    warrantyList: null,
};
export default function unitReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CLAIM_TYPE_LIST:
            return {
                ...state,
                claimTypeList: action.val,
            };
        case SET_COUNTRY_LIST:
            return {
                ...state,
                countryList: action.val,
            };
        case SET_BANK_LIST:
            return {
                ...state,
                bankList: action.val,
            };
        case SET_BANK_COUNTRY_LIST:
            return {
                ...state,
                bankCountryList: action.val,
            };
        case SET_LANGUAGE_LIST:
            return {
                ...state,
                languageList: action.val,
            };
        case SET_PARTNER_LIST:
            return {
                ...state,
                partnerList: action.val,
            };
        case SET_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: action.val,
            };
        case SET_SHOP_LIST:
            return {
                ...state,
                shopList: action.val,
            };
        case SET_PRODUCT_GROUP_LIST:
            return {
                ...state,
                productGroupList: action.val,
            };
        case SET_PRODUCT_BRAND_LIST:
            return {
                ...state,
                productBrandList: action.val,
            };
        case SET_WARRANTY_LIST:
            return {
                ...state,
                warrantyList: action.val,
            };
        case RESET:
            return initialState;
        default:
            return state;
    }
}
