import AuthService from '@src/api/services/authService';
import RootState from '@src/interfaces/RootState';
import { reset } from 'redux-form';
import { put, select } from 'redux-saga/effects';
import { SET_ERROR_OBJECT } from '../error/actions';
import { SET_LOADING, SET_PASSWORD_MODAL } from '../ui/actions';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export function* changePassword() {
    yield put({ type: SET_LOADING, val: true });

    const payload = {
        auth: {
            username: yield select((state: RootState) => state?.form?.loginForm?.values?.username),
            password: yield select((state: RootState) => (state?.form?.changePassword as Record<string, Record<string, string>>)?.values?.temporaryPassword),
            newPassword: yield select((state: RootState) => (state?.form?.changePassword as Record<string, Record<string, string>>)?.values?.newPassword),
        },
    };
    try {
        const response = yield AuthService.changePassword(payload);
        if (response.status === 200) {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'reset_password_errors', errorCode: response?.status } });
            yield put({ type: SET_PASSWORD_MODAL, val: false });
            sessionStorage.clear();
            localStorage.clear();
            yield put(reset('loginForm'));
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'reset_password_errors', errorCode: error?.response.status } });
        console.log(error.response);
    }

    yield put({ type: SET_LOADING, val: false });
}
export function* forgottenPassword() {
    yield put({ type: SET_LOADING, val: true });
    const payload = {
        auth: {
            username: yield select((state: RootState) => state?.form?.loginForm?.values?.username),
            email: yield select((state: RootState) => state?.form?.loginForm?.values?.email),
        },
    };
    try {
        const response = yield AuthService.forgotPassword(payload);
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: error?.response?.status } });
    }

    yield put({ type: SET_LOADING, val: false });
}
export function* refreshToken() {
    yield put({ type: SET_LOADING, val: true });

    try {
        const response = yield AuthService.tokenRefresh();
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'token_error', errorCode: error?.response?.status } });
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('username');
    }

    // if (response.status === 401) {
    //     yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'token_error', errorCode: response?.status } });
    //     sessionStorage.removeItem('token');
    //     sessionStorage.removeItem('username');
    // }

    yield put({ type: SET_LOADING, val: false });
}
export function* performLogin() {
    yield put({ type: SET_LOADING, val: true });
    const payload = {
        auth: {
            username: yield select((state: RootState) => state?.form?.loginForm?.values?.username),
            email: yield select((state: RootState) => state?.form?.loginForm?.values?.password),
        },
    };
    const response = yield AuthService.performLogin(payload);

    yield put({ type: SET_LOADING, val: false });
}
