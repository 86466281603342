import React, { ReactNode } from 'react';
import { Button } from '@mui/material';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface ButtonProps {
    buttonType: 'button' | 'submit' | 'reset';
    fullWidth?: boolean;
    variant?: string;
    isDisabled?: boolean;
    bgColor?: string;
    level?: 'primary' | 'secondary' | 'tertiary';
    name?: string;
    children: string | ReactNode;
    onClick?: (value?: any) => void;
    classname?: string;
    id?: string;
}

// TODO https://stackoverflow.com/questions/41725725/access-css-variable-from-javascript
const RenderButton = ({ buttonType, level, children, onClick, classname, id, isDisabled, name }: ButtonProps) => {
    return (
        <Button id={id} type={buttonType} name={name} disabled={isDisabled} className={`${classname} ${level}-btn`} sx={{ margin: '.7rem 0' }} fullWidth onClick={onClick}>
            {children}
        </Button>
    );
};

export default RenderButton;
