import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
    palette: {
        primary: {
            main: '#009793',
        },
        secondary: {
            main: '#11cb5f',
        },
    },
});
