import { FormControl, InputLabel, MenuItem, Select, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as SelectInterfaces from '@interfaces/SelectInterface';
import { theme } from './theme';

// // Pass an Array of objects with the select options (as options)
// // Reference => SelectInterfaces
// // [
// //   { name: 'EN', value: 'en', label: 'EN' },
// //   { name: 'FR', value: 'fr', label: 'FR' },
// //   { name: 'NL', value: 'nl', label: 'NL' },
// // ]

interface SelectFieldProps {
    options: SelectInterfaces.SelectList;
    meta: Record<string, string | boolean>;
    defaultValue?: string;
    value: any;
    placeholder: string;
    onChange: (value: SelectInterfaces.SelectListItem) => void;
    className?: string;
}

type RenderSelectFieldProps = {
    input?: any;
    options: SelectInterfaces.SelectList;
    meta: Record<string, string | boolean>;
    className?: string;
    placeholder: string;
    defaultValue?: Record<string, string | number> | string | number;
};

const SelectField: React.FC<SelectFieldProps> = ({ onChange, value, options, placeholder, meta, className }) => {
    // Function that runs when select an option
    const handleChange = (option) => {
        onChange(option);
        // setSelectedOption(option.target.value);
    };
    // const getOptionIndex = (name) => {
    //     console.log('file: Select.tsx:43 ~ getOptionIndex ~ name', name);

    //     console.log('file: Select.tsx:40 ~ getOptionIndex ~ options', options);
    //     for (let i = 0; i < options?.length; i++) {
    //         if (options[i].name === name) {
    //             return i;
    //         }
    //     }
    // };
    // Iterate through options
    const optionItems = options.map((item, index) => {
        return (
            <MenuItem key={`${item.name}-${index}`} value={item.value}>
                {item.name}
            </MenuItem>
        );
    });
    // useEffect(() => {
    //     setSelectedOption({ ...value });
    // }, []);
    return (
        <ThemeProvider theme={theme}>
            <FormControl onChange={handleChange} fullWidth>
                <InputLabel error={meta.touched && meta.invalid ? true : false}>{placeholder}</InputLabel>
                <Select
                    className={className}
                    error={meta.touched && meta.invalid ? true : false}
                    onChange={handleChange}
                    labelId={`${className}-label`}
                    id={className}
                    value={value}
                    defaultValue="en"
                    label={placeholder}
                >
                    {optionItems}
                </Select>
            </FormControl>
        </ThemeProvider>
    );
};

const renderSelectField: React.FC<RenderSelectFieldProps> = ({ input, meta, options, placeholder, className, defaultValue }) => {
    return (
        <div
            className={
                meta.touched && meta.error
                    ? 'form__form-group-input-wrap form__form-group-input-wrap--error selectHasError z-1000'
                    : 'form__form-group-input-wrap form__form-group-input-wrap--error z-1000'
            }
        >
            <SelectField {...input} options={options} placeholder={placeholder} defaultValue={defaultValue} meta={meta} className={className} />
            {meta.touched && meta.error && (
                <span className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">{meta.error}</span>
            )}
        </div>
    );
};

export default renderSelectField;
