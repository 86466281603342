import React from 'react';
import i18next from 'i18next';
import { config as i18NextConfig } from '../translations/index';
import store from '../redux/store';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import { AuthProvider } from '@src/contexts/auth/authProvider';
// Init i18next
i18next.init(i18NextConfig);

export const App = () => {
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <AuthProvider>
                    <BrowserRouter>
                        <Router />
                    </BrowserRouter>
                </AuthProvider>
            </I18nextProvider>
        </Provider>
    );
};
