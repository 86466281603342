import axiosInstance from '../http/axiosSetup';
import { LoginPayload } from '../interfaces/Login';

export default class AuthService {
    static performLogin = (body: LoginPayload) =>
        axiosInstance
            .post('/login', body)
            .then((res) => {
                res.data.status = res.status;
                res.data.token = res.headers?.authorization;
                return res;
            })
            .catch((err) => {
                console.error('file: authService.ts:14 ~ AuthService ~ err:', err);

                // return err?.response;
                throw err;
            });

    static forgotPassword = (body: object) =>
        axiosInstance.post('/password/forgotten', body).then((res) => {
            return res;
        });

    static changePassword = (body: object) =>
        axiosInstance.post('/password/change', body).then((res) => {
            return res;
        });

    static tokenRefresh = () => {
        return axiosInstance.get('refresh/token').then((res) => {
            const token = res?.headers?.authorization;
            sessionStorage.setItem('token', token);
            return res;
        });
    };
}
