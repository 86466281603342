import React from 'react';
import './styles/app.scss';
import { App } from './app/App';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

async function deferRender() {
    if (process.env.NODE_ENV === 'development' && process.env.MOCK == 'true') {
        // The mock is on
        const { worker } = await import('./mocks/browser');
        return worker.start();
    }
}

deferRender().then(() => {
    root.render(<App />);
});
