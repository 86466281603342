// import { reduxForm } from 'redux-form';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyCookie = () => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');

    /* FUNCTIONS */

    /* API FUNCTIONS */

    /* USEEFFECTS */

    return (
        <Box sx={{ backgroundColor: '#F2F2F4', textAlign: 'center', margin: '2rem auto', padding: '2rem 1rem' }}>
            <article>
                <div dangerouslySetInnerHTML={{ __html: t('privacy') }} />
            </article>
        </Box>
    );
};

export default PrivacyCookie;

// export default reduxForm({
//     form: '{{form}}',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     touchOnBlur: false
// })(PrivacyCookie)
