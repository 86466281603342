import UnitService from '@src/api/services/UnitService';
import CountryModel from '@src/api/models/CountryModel';
import LanguageModel from '@src/api/models/LanguageModel';
import RootState from '@src/interfaces/RootState';
import { change } from 'redux-form';
import { put, select } from 'redux-saga/effects';
import { SET_ERROR_OBJECT } from '../error/actions';
import { SET_LOADING } from '../ui/actions';

/** Saga Actions */
export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
export const GET_BANK_LIST = 'GET_BANK_LIST';
export const GET_BANK_COUNTRY_LIST = 'GET_BANK_COUNTRY_LIST';
export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const GET_PARTNER_LIST = 'GET_PARTNER_LIST';
export const GET_SHOP_LIST = 'GET_SHOP_LIST';
export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST';
export const GET_PRODUCT_GROUP_LIST = 'GET_PRODUCT_GROUP_LIST';
export const GET_PRODUCT_BRAND_LIST = 'GET_PRODUCT_BRAND_LIST';
export const GET_WARRANTY_LIST = 'GET_WARRANTY_LIST';
export const GET_WARRANTY_PRICE_LIST = 'GET_WARRANTY_PRICE_LIST';
/** Reducer Actions */
export const SET_CLAIM_TYPE_LIST = 'SET_CLAIM_TYPE_LIST';
export const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST';
export const SET_BANK_LIST = 'SET_BANK_LIST';
export const SET_BANK_COUNTRY_LIST = 'SET_BANK_COUNTRY_LIST';
export const SET_LANGUAGE_LIST = 'SET_LANGUAGE_LIST';
export const SET_PARTNER_LIST = 'SET_PARTNER_LIST';
export const SET_SHOP_LIST = 'SET_SHOP_LIST';
export const SET_EMPLOYEE_LIST = 'SET_EMPLOYEE_LIST';
export const SET_PRODUCT_GROUP_LIST = 'SET_PRODUCT_GROUP_LIST';
export const SET_PRODUCT_BRAND_LIST = 'SET_PRODUCT_BRAND_LIST';
export const SET_WARRANTY_LIST = 'SET_WARRANTY_LIST';
export const RESET = 'RESET';

/**
 * Warranty Price List
 */
export function* getWarrantyPriceList() {
    yield put({ type: SET_LOADING, val: true });
    const partnerForm = yield select((state: RootState) => state?.form?.partnerForm?.values);
    const selectedWarranty = yield select((state: RootState) => state?.form?.productForm?.values?.warranty);
    const warrantyList = yield select((state: RootState) => state?.unit?.warrantyList);
    const payload = {
        prt_id: partnerForm?.partner,
        pricecode: warrantyList?.find((item) => item.value == selectedWarranty).pricecode,
        start_date: partnerForm?.deliveryDate,
        end_date: partnerForm?.deliveryDate,
    };
    try {
        const response = yield UnitService.warrantyPriceList(payload);
        yield put(change('productForm', 'warrantyPrice', response?.data[0]?.warrantyprice));
        yield put(change('productForm', 'warrantySellingPrice', response?.data[0]?.warrantyprice));
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: error?.response?.status } });
    }

    yield put({ type: SET_LOADING, val: false });
}
/**
 * Warranty List
 */
export function* getWarrantyList() {
    yield put({ type: SET_LOADING, val: true });
    yield put({ type: SET_WARRANTY_LIST });
    const partnerForm = yield select((state: RootState) => state?.form?.partnerForm?.values);

    const productForm = yield select((state: RootState) => state?.form?.productForm?.values);
    const queryParams = {
        partnerId: partnerForm?.partner,
        price: productForm?.price,
        groupId: productForm?.group,
    };

    try {
        const response = yield UnitService.warrantyList(queryParams);

        if (response.status == 200) {
            const warranties = response?.data?._embedded?.warrantyTypes;

            warranties.forEach((warranty) => {
                warranty.value = warranty?.id;
                warranty.label = warranty?.name;
                warranty.name = warranty?.name;
            });
            yield put({ type: SET_WARRANTY_LIST, val: warranties });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

/**
 * Product Group List
 */
export function* getProductGroupList() {
    yield put({ type: SET_LOADING, val: true });
    const isProductGroupListStored = yield select((state: RootState) => state?.unit?.productGroupList);
    /** Check if already stored any employee list
     * if stored then return, DO NOT send api request for it
     */
    if (isProductGroupListStored != null) {
        yield put({ type: SET_LOADING, val: false });
        return;
    }

    const partnerId = yield select((state: RootState) => state?.form?.partnerForm?.values?.partner);

    const queryParams = {
        partnerId: partnerId,
        limit: 1000,
    };
    try {
        const response = yield UnitService.productGroupList(queryParams);

        if (response.status == 200) {
            // Sort by date
            const sortedArrByDate = response?.data.sort((a, b) => new Date(b.startDate?.replace('T00:00:00', '')).getTime() - new Date(a.startDate?.replace('T00:00:00', '')).getTime());

            // Filter duplication
            const filteredArr = response?.data.filter((val, i, self) => i === self.findIndex((t) => t.name === val.name));

            // Append the required keys
            filteredArr.forEach((item) => {
                item.value = item?.id;
                item.label = item?.name;
                item.name = item?.name;
            });
            // Filter to only GSM and Smartphone
            const filteretToGsmSmartphone = filteredArr.filter((item) => item.id == '5' || item.id == '6');

            yield put({ type: SET_PRODUCT_GROUP_LIST, val: filteretToGsmSmartphone });
            // yield put({ type: SET_PRODUCT_GROUP_LIST, val: resultArray });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

/**
 * Product Brand List
 */
export function* getProductBrandList() {
    yield put({ type: SET_LOADING, val: true });

    const isProductBrandListStored = yield select((state: RootState) => state?.unit?.productBrandList);
    /** Check if already stored any product brand list
     * if stored then return, DO NOT send api request for it
     */
    if (isProductBrandListStored != null) {
        yield put({ type: SET_LOADING, val: false });
        return;
    }
    const partnerId = yield select((state: RootState) => state?.form?.partnerForm?.values?.partner);
    try {
        const payload = {
            partnerId: partnerId,
        };
        const response = yield UnitService.productBrandList(payload);

        if (response.status == 200) {
            const resultArray: Array<any> = [];
            // Append value, label and name to fit for select field
            // Append the required keys
            for (const data of response?.data) {
                resultArray.push({
                    value: data.id,
                    label: data.name,
                    name: data.name,
                    info: data,
                });
            }
            yield put({ type: SET_PRODUCT_BRAND_LIST, val: resultArray });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
/**
 * Employee List
 */
export function* getEmployeeList(action) {
    yield put({ type: SET_LOADING, val: true });

    const isEmployeeListStored = yield select((state: RootState) => state?.unit?.employeeList);
    /** Check if already stored any employee list
     * if stored then return, DO NOT send api request for it
     */
    // if (isEmployeeListStored != null) {
    //     yield put({ type: SET_LOADING, val: false });
    //     return;
    // }

    const partnerId = yield select((state: RootState) => state?.form?.partnerForm?.values?.partner);
    // const payload = {
    //     shop_id: action?.val?.shopId,
    //     prt_id: partnerId,
    // };

    const response = yield UnitService.employeeList(partnerId, action?.val?.shopId);

    if (response.status == 200) {
        response.data?.forEach((item) => {
            item.value = item.id;
            item.label = item.name;
            item.name = item.name;
        });
        yield put({ type: SET_EMPLOYEE_LIST, val: response.data });
    } else {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
    }
    yield put({ type: SET_LOADING, val: false });
}
/**
 * Shop List
 */
export function* getShopList() {
    yield put({ type: SET_LOADING, val: true });

    // const isShopListStored = yield select((state: RootState) => state?.unit?.shopList);
    /** Check if already stored any shop list
     * if stored then return, DO NOT send api request for it
     */
    // if (isShopListStored != null) {
    //     yield put({ type: SET_LOADING, val: false });
    //     return;
    // }

    const partnerId = yield select((state: RootState) => state?.form?.partnerForm?.values?.partner);

    const payload = {
        partnerId: partnerId,
    };

    const response = yield UnitService.shopList(payload);
    if (response.status == 200) {
        response.data.forEach((item) => {
            item.value = item.id;
            item.label = item.name;
            item.name = item.name;
        });
        yield put({ type: SET_SHOP_LIST, val: response.data });
    } else {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
    }
    yield put({ type: SET_LOADING, val: false });
}

/**
 * Partner List
 */
export function* getPartnerList() {
    yield put({ type: SET_LOADING, val: true });
    const isPartnerListStored = yield select((state: RootState) => state?.unit?.partnerList);
    /** Check if already stored any partner list
     * if stored then return, DO NOT send api request for it
     */
    if (isPartnerListStored != null) {
        yield put({ type: SET_LOADING, val: false });
        return;
    }
    try {
        /**
         * Get request for Claim types
         */
        const response = yield UnitService.partnerList();
        if (response.status == 200) {
            response.data.forEach((item) => {
                item.value = item.partnerId;
                item.label = item.partnerName;
                item.name = item.partnerName;
                delete item.parterId;
                delete item.partnerName;
            });

            response.data.sort((a, b) => a.name.localeCompare(b.name));

            yield put({ type: SET_PARTNER_LIST, val: response.data });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

/**
 * Country list
 */
export function* getCountryList() {
    yield put({ type: SET_LOADING, val: true });
    const isCountriesStored = yield select((state: RootState) => state?.unit?.countryList);
    /** Check if already stored any country list
     * if stored then return, DO NOT send api request for it
     */
    if (isCountriesStored != null) {
        yield put({ type: SET_LOADING, val: false });
        return;
    }

    const response = yield UnitService.countryList();

    if (response.status == 200) {
        const countryList: any = [];
        response.data?.supportedCountries.forEach((item, index) => {
            const countryItem = new CountryModel(item);
            countryList.push(countryItem);
        });
        yield put({ type: SET_COUNTRY_LIST, val: countryList });
    } else {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
    }
    yield put({ type: SET_LOADING, val: false });
}

/**
 * Langauge list
 */
export function* getLanguageList() {
    yield put({ type: SET_LOADING, val: true });
    const isLanguagesStored = yield select((state: RootState) => state?.unit?.languageList);
    /** Check if already stored any language list
     * if stored then return, DO NOT send api request for it
     */
    if (isLanguagesStored != null) {
        yield put({ type: SET_LOADING, val: false });
        return;
    }

    const response = yield UnitService.languageList();

    if (response.status == 200) {
        const languageList: any = [];
        response.data.supportedLanguages.forEach((item) => {
            const countryItem = new LanguageModel(item);
            languageList.push(countryItem);
        });
        yield put({ type: SET_LANGUAGE_LIST, val: languageList });
    } else {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
    }
    yield put({ type: SET_LOADING, val: false });
}
