import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button } from '@MyComponents';
import { AuthContext } from '@src/contexts/auth/authContext';
import { RESET, SET_BACKDROP } from '@src/redux/ui/actions';
import AttachmentIcon from 'mdi-react/AttachmentIcon';
import ClockIcon from 'mdi-react/ClockFastIcon';
import HomeIcon from 'mdi-react/HomeAccountIcon';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
// const pages = ['Home', 'New Claim', 'Claim History', 'Contact'];

const ResponsiveAppBar = ({ pathname }) => {
    /* STATES */
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    /* VARIABLES */
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    // const {
    //     location: { pathname },
    // } = useHistory();

    const {
        authState: { isAuthenticated },
    } = useContext<any>(AuthContext);
    const { authDispatch } = useContext<any>(AuthContext);
    const pages = [
        // { page: 'Home', url: '/' },
        { page: t('navigation.dashboard'), url: 'dashboard', icon: <HomeIcon /> },
        { page: t('navigation.new_contract'), url: 'new-contract', icon: <AttachmentIcon /> },
        // { page: t('navigation.claim_history'), url: 'claim-history', icon: <ClockIcon /> },
        // { page: t('navigation.contact'), url: 'contact', icon: <AttachmentIcon /> },
        // { page: 'Claim', url: 'claim' },
        // { page: 'Claim History', url: 'claim-history' },
        // { page: 'Contact', url: 'contact' },
    ];
    /* FUNCTIONS */
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
        dispatchStore({ type: SET_BACKDROP, val: true });
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);

        dispatchStore({ type: SET_BACKDROP, val: false });
    };

    const handleLogout = () => {
        // TODO Handling logout from SAGA -> session clear, reset redux. Useful when login expires
        sessionStorage.clear();
        dispatchStore({ type: RESET });
        authDispatch({ type: 'SIGNED_OUT' });
        // history.push('/');
    };

    /* USEEFFECTS */
    // Every render check if cookie exists, if so, run dispatch
    useEffect(() => {
        if (sessionStorage.getItem('token') !== null) authDispatch({ type: 'SIGNIN_SUCCESS' });
    }, []);
    return (
        <>
            {/* {isAuthenticated && ( */}
            <AppBar className="navigation" position="static" sx={{ padding: '10px' }}>
                <Container maxWidth="lg">
                    <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {/* PROJECT LOGO */}
                        <Link to={isAuthenticated ? '/new-contract' : '/'}>
                            <img src="/images/base_logo.png" className="navigation-logo" />
                        </Link>
                        {/* DESKTOP MENU over 992px*/}
                        {isAuthenticated && (
                            <div className="desktop-menu">
                                {pages.map((page) => {
                                    return (
                                        <Button key={`xs-${page.page}`} buttonType={'button'}>
                                            <Link
                                                className={`navigation__link navigation__link-desktop ${pathname.replace('/', '') === page.url ? 'active' : ''}`}
                                                key={`xs-${page.page}`}
                                                to={page.url}
                                            >
                                                <MenuItem onClick={handleCloseNavMenu} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' } }}>
                                                    {/* {page.icon} */}
                                                    <Typography sx={{ marginLeft: '10px', fontWeight: '500' }} textAlign="center">
                                                        {page.page}
                                                    </Typography>
                                                </MenuItem>
                                            </Link>
                                        </Button>
                                    );
                                })}
                                <Button buttonType={'button'} classname="logout-button">
                                    <div className="navigation__link navigation__link-desktop">
                                        <MenuItem className="logout-button__item" sx={{ textTransform: 'none' }}>
                                            <Box sx={{ display: 'flex' }} onClick={handleLogout}>
                                                <LogoutIcon />
                                                <Typography sx={{ marginLeft: '10px', fontWeight: '500' }} textAlign="center">
                                                    {t('navigation.logout')}
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                    </div>
                                </Button>
                            </div>
                        )}
                        {/* HAMBURGER MENU ICON + LIST under 992px*/}
                        {isAuthenticated && (
                            <Box className="hamburger-menu" sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'end' }}>
                                <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                                    <MenuIcon id="hamburger-icon" />
                                </IconButton>

                                <Menu
                                    disableScrollLock={true}
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        width: '400px',
                                        display: { xs: 'block' },
                                        // position: 'absolute',
                                        top: '0',
                                        right: '0',
                                    }}
                                >
                                    {pages.map((page) => {
                                        return (
                                            <Button key={`xs-${page.page}`} buttonType={'button'}>
                                                <Link className="navigation__link" key={`xs-${page.page}`} to={page.url}>
                                                    <MenuItem onClick={handleCloseNavMenu} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' } }}>
                                                        {page.icon}
                                                        <Typography sx={{ marginLeft: '10px', fontWeight: '500' }} textAlign="center">
                                                            {page.page}
                                                        </Typography>
                                                    </MenuItem>
                                                </Link>
                                            </Button>
                                        );
                                    })}
                                    <Button buttonType={'button'} classname="logout-button">
                                        <div className="navigation__link">
                                            <MenuItem className="logout-button__item" sx={{ textTransform: 'none' }}>
                                                <Box sx={{ display: 'flex' }} onClick={handleLogout}>
                                                    <LogoutIcon />
                                                    <Typography sx={{ marginLeft: '10px', fontWeight: '500' }} textAlign="center">
                                                        {t('navigation.logout')}
                                                    </Typography>
                                                </Box>
                                            </MenuItem>
                                        </div>
                                    </Button>
                                </Menu>
                            </Box>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
            {/* )} */}
        </>
    );
};
export default React.memo(ResponsiveAppBar);
